import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../mixins/dynamic-head";

//sections


export default {
    name: "manuals",
    components: {},
    mixins: [dynamicHead],
    data() {
        return {
            bannerContent: '',
            seoData: ''
        }
    },
    beforeUpdate() {

    },
    mounted() {
        this.getPageContent('manuals').then(() => {
            this.setPageItem(this.pageContent)
            document.title = `${this.pageContent.title} | karbosnab`
            this.bannerContent = this.pageContent
            this.seoData = this.pageContent.seo.data
        })
    },
    watch: {
        '$route.params.slug': {
            handler: function () {
                document.title = `${this.pageContent.title} | karbosnab`
                this.getBannerImage()
            },
        }
    },
    created() {

        this.getManuals().then(() => {
            this.getBannerImage()
        })
        // this.getPageContent('manuals')
        this.setRequestFlag(true);
    },

    computed: {
        ...mapGetters({
            manuals: 'manuals/manuals',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        }),

    },

    methods: {
        ...mapActions({
            getManuals: 'manuals/GET_MANUALS',
            getPageContent: 'setting/GET_PAGE_CONTENT',

        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        getBannerImage() {
            let currentManual
            this.manuals.find(el => {
                if (el.slug === this.$route.params.slug) {
                    currentManual = el
                }
            })
            if (!currentManual) {
                return this.bannerContent = this.pageContent
            } else {
                return this.bannerContent = currentManual
            }
        }
    }
}
